import React, { useState, useEffect, useMemo, useRef, ChangeEvent } from 'react'
import { BsCloudArrowUp as ImportIcon } from 'react-icons/bs'
import * as yup from 'yup'
import { prepareUpdateItemObject } from './item.helpers'
import { useLocation } from '@reach/router'
import { BiColumns } from 'react-icons/bi'
import { formatPrice } from '../../../constants/global'
import { TableWrapper } from '../../common/table/TableWrapper'
import {
   Accordion,
   CsvDownloadForm,
   LoadingOverlay,
   PageHeader,
   Search,
   Image,
   Modal,
   Button,
   CrudItems,
   ItemReorder,
   SlidePanel,
   SnackBar,
   Drawer,
   Alert,
   Svg,
   ImportItemsModal,
} from '../../common'
import {
   GpsScan,
   Item,
   SearchObject,
   ToolStatus,
   ToolTypes,
} from '../../../types'
import FilterButton from '../../common/filters/filterButton/filterButton'
import ColumnsButton from '../../common/filters/columnsButton/columnsButton'
import FilterTags from '../../common/filters/filterTags/filterTags'
import { Actions, Views, PAGINATION } from '../../../constants/tables'
import {
   formatUsDate,
   removeMinYear,
   formatLocaleDate,
   disableKeyboardInput,
   enableKeyboardInput,
} from '../../../common/utils/functions'
import { RadioButtonContainer, RadioButton } from '../../common'
import { Select } from '../../common/form/fields'
import { Severity } from '../../../types'
import { Item as ReorderItem } from '../../common/itemReorder/itemReorder.type'
import { CRUD_TABLE } from './constants'
import ItemMapView from './itemMapView/itemMapView'
import * as tableStyles from '../../common/table/table.module.scss'
import { WarningModal } from '../../reusableModals/warningModal'
import { ManageKitModal } from './modals/manageKitModal'

const {
   extraClassName,
   placeholder,
   defaultActiveValue,
   addButtonText,
   key,
   crudPaths,
   tableTitle,
   radioToolStatus,
   radioToolStatusEmployeeUser,
   displayView,
   modalTitle,
   exportPrimaryActionText,
   exportSecondaryActionText,
   basicPath,
   downloadRequest,
   exportFileName,
   downloadCsvText,
   locationTrackingArr,
   auditArr,
   quantityArr,
   createDefaultSchema,
   defaultColumnOrder,
} = CRUD_TABLE

const { companyCheckoutOptions, employeeCheckoutOptions } = ACTION_CONSTANTS

import {
   useCategories,
   useFilters,
   useItems,
   useItemImportItems,
   useItemGetKits,
   useItemTypes,
   useTotalCount,
   useConnections,
   useConditions,
   useActiveLocations,
   useSettings,
   useManufacturers,
   useItemNotes,
   useItemHistory,
   useItemsView,
   useTableOptions,
   useProfile,
   useBarcodeLookup,
} from '../../../hooks'
import * as styles from '../../common/search/search.module.scss'
import { Option } from '../../common/form/fields/dropdown/dropdown.type'
import {
   Filters as FiltersObject,
   FiltersForAPI,
   FilterValue,
   SortObject,
   SortDirection,
} from '../../../hooks/useFilters/useFilters.type'
import { DropdownFilter } from '../../common/filters/filterTypes'
import { useItemUpload } from '../../../hooks'

import {
   convertLocationToOptions,
   convertManufacturersToOptions,
   convertConnectionsToOptions,
   convertConditionsToOptions,
   convertItemTypesToOptions,
   convertItemStatusStatesToOptions,
   convertCategoriesToOptions,
   convertNoteTypesToOptions,
   convertTableSortColumnOptionsListToOptions,
} from '../../common/filters/helpers'
import { renderOrderedTableColumns } from '../../../common/utils/table'
import { format } from 'date-fns'
import { FORMATS } from '../../../constants/datetime'
import { flattenFilterObj } from '../../../hooks/useFilters/useFilters.helpers'
import {
   isAdminRole,
   isCompanyRole,
   isEmployeeRole,
} from '../../../common/utils/roles'
import { AddToKitModal, DeleteKit } from '../../common/crudItems'
import { CheckoutModal } from '../../actionsWorkflow/checkoutModal'
import { ACTION_CONSTANTS } from '../actions/actionConstants'
import { ItemQuantityModal } from './modals/itemQuantityModal'
import { TableSortHeader } from '../../common/table/tableSortHeader'
import SortButton from '../../common/filters/sortButton/sortButton'
import { ColumnSortPanel } from './columnSortPanel/columnSortPanel'
import useTableSortColumnOptions from '../../../hooks/useTableSortColumnOptions/useTableSortColumnOptions'
import { useMediaQuery } from 'react-responsive'
import { CrudItemsParentView } from '../../../enums/CrudItemsEnums'

import { ItemButton } from '../../common/modal/types'
import { ImportItemModalViews } from '../../common/importItemsModal/importItemsModal.type'

const schema = yup.object().shape({})

export function formatFiltersForAPI(filters: FiltersObject): FiltersForAPI {
   function formatDate(date: Date) {
      return format(date, FORMATS.filterDateAPI)
   }

   const startDateFilter = {
      ...(filters?.startDate?.value instanceof Date && {
         startDate: formatDate(filters?.startDate?.value),
      }),
   }
   const endDateFilter = {
      ...(filters?.endDate?.value instanceof Date && {
         endDate: formatDate(filters?.endDate?.value),
      }),
   }

   return {
      ...flattenFilterObj(filters),
      ...startDateFilter,
      ...endDateFilter,
   }
}

function generateColumnItemOptions(
   colList: string[],
   columns: any[]
): ReorderItem[] {
   return colList.map((colId) => {
      const tableCol = columns.find((col) => col?.id === colId)
      return {
         value: tableCol?.id,
         label: tableCol?.headerTitle
            ? tableCol?.headerTitle
            : tableCol?.Header,
      }
   })
}

export const Items = (): JSX.Element => {
   const location = useLocation()
   const { profile, refresh: refreshProfile } = useProfile()

   const userRoles = profile?.Roles || []
   const isEmployeeUser = isEmployeeRole(userRoles)
   const isAdminCompanyUser = isAdminRole(userRoles) || isCompanyRole(userRoles)

   const {
      isLoading: tableColumnOptionsLoading,
      tableColumnOptions: itemTableOptions,
      update: saveTableColumnOptions,
   } = useTableOptions('itemsTable')

   const { list: activeWarehouse } = useActiveLocations({
      cacheIdentifier: 'active-warehouses',
      warehousesOnly: true,
   })

   const { query, skip, take } = PAGINATION
   const { settings } = useSettings()
   const isMobile = useMediaQuery({ query: '(max-width: 1000px)' })

   const {
      CustomDate1Label,
      CustomDate1Enabled,
      CustomDate2Label,
      CustomDate2Enabled,
      CustomText1Label,
      CustomText1Enabled,
      CustomText2Label,
      CustomText2Enabled,
      CustomURL1Label,
      CustomURL1Enabled,
      CustomURL2Label,
      CustomURL2Enabled,
      CustomRentalRate1Label,
      CustomRentalRate1Enabled,
      CustomRentalRate2Label,
      CustomRentalRate2Enabled,
      CustomRentalRate3Label,
      CustomRentalRate3Enabled,
      CustomRentalRate4Label,
      CustomRentalRate4Enabled,
      KitEnabled,
   } = settings

   const buttonRef = useRef<HTMLInputElement>(null)
   const resetRef = useRef<HTMLInputElement>(null)

   const {
      data: itemData,
      msg,
      setPath,
      setPageOptions,
      list,
      error,
      remove,
      add,
      update,
      refetch: refetchItemData,
      isLoading,
      isRefetching,
      isSuccessRemove,
      isErrorUpdate,
      isSuccessUpdate,
      isUpdating,
      isErrorRemove,
      errorUpdate,
      isErrorAdd,
      isSuccessAdd,
      isAdding,
   } = useItems()

   const {
      upload,
      changePosition,
      deleteImage,
      isDeleteSuccess,
      isPositionLoading,
      isPositionSuccess,
      isImporting,
   } = useItemUpload()

   const {
      importItems,
      isImportingItems,
      isImportItemsSuccess,
      importError,
      importFileErrors,
      resetImportItems,
      totalImagesImported,
      totalItemsImported,
   } = useItemImportItems()

   const [uploadPercentage, setUploadPercentage] = useState<number>(0)

   const [isVisibleSnackbar, setIsVisibleSnackbar] = useState(false)
   const [message, setMessage] = useState('')
   const [severityStatus, setSeverityStatus] = useState<Severity>(null)

   const closeSnackbar = () => {
      setIsVisibleSnackbar(false)
      setMessage(null)
      setSeverityStatus(null)
   }

   const { onSubmitBarcodelookup } = useBarcodeLookup()

   useEffect(() => {
      if (isEmployeeUser) {
         setQuickFilterOptions(radioToolStatusEmployeeUser)
      }
   }, [isEmployeeUser])

   useEffect(() => {
      if (isErrorRemove) {
         setIsVisibleSnackbar(true)
         setSeverityStatus(Severity.ERROR)
         setMessage(error)
      }
   }, [isErrorRemove])

   useEffect(() => {
      if (isSuccessRemove) {
         setIsVisibleSnackbar(true)
         setSeverityStatus(Severity.SUCCESS)
         setMessage('Item has been successfully deleted')
         // get new count on removal
         const criteria = {
            query,
            ...filterValues,
         }
         getCount(criteria)
      }
   }, [isSuccessRemove])

   const {
      errors,
      modify,
      modifyMulti,
      reset,
      remove: removeFilter,
      save,
      savedFilters,
      tmpFilters,
   } = useFilters(schema, {})

   const { list: itemTypes } = useItemTypes()
   const { list: connections } = useConnections()
   const { list: conditions } = useConditions()
   const { list: activeLocations, isLoading: isLoadingLocations } =
      useActiveLocations()
   const {
      list: manufacturers,
      refetchManufacturers,
      isLoadingManufacturers,
   } = useManufacturers()
   const {
      list: categories,
      refetchCategories,
      isLoadingCategories,
   } = useCategories()
   const { list: tableSortColumnOptionsList } = useTableSortColumnOptions()
   const { data: totalCount, setCountPath, getCount } = useTotalCount({})

   const [quickFilterOptions, setQuickFilterOptions] = useState<
      { label: string; status: ToolStatus }[]
   >(!isEmployeeUser ? radioToolStatus : radioToolStatusEmployeeUser)

   const [searchMode, setSearchMode] = useState(false)
   const [pageSized, setPageSized] = useState(PAGINATION.pageSized)
   const [isPageReset, setIsPageReset] = useState(false)
   const [isModalVisible, setIsModalVisible] = useState(false)
   const [isManageKitModalOpen, setIsManageKitModalOpen] = useState(false)
   const [filtersOpen, setFiltersOpen] = useState(false)
   const [isSortPanelOpen, setIsSortPanelOpen] = useState<boolean>(false)
   const [columnSettingsOpen, setColumnSettingsOpen] = useState(false)
   const [filterValues, setFilterValues] = useState({})
   const formRef = useRef<HTMLFormElement>()
   const sortRef = useRef<HTMLFormElement>()
   const [activeValue, setActiveValue] = useState({})
   const [searchCriteria, setSearchCriteria] = useState('')
   const [view, setView] = useState<number>(Views.Tile)
   const [fullDownload] = useState(downloadRequest)
   const [isDownloading, setIsDownloading] = useState(false)
   const [isModalExportVisible, setIsExportModalVisible] = useState(false)
   const [isImportModalVisible, setIsImportModalVisible] = useState(false)
   const [checkoutOptions, setCheckoutOptions] = useState<any>({})
   const [isCheckoutModalOpen, setIsCheckoutModalOpen] =
      useState<boolean>(false)
   const [isQuantityModalOpen, setIsQuantityModalOpen] =
      useState<boolean>(false)
   const [checkoutAction, setCheckoutAction] = useState<any>(checkoutOptions[0])
   const [selectedItemsForWorkflow, setSelectedItemsForWorkflow] = useState<
      Item[]
   >([])
   const [itemId, setItemId] = useState<number | null>()
   const [itemTitle, setItemTitle] = useState<any>()
   const [itemManufacturerId, setItemManufacturerId] = useState<any>()
   const [itemCategory, setItemCategory] = useState<any>()
   const [itemAction, setItemAction] = useState<any>() // add, edit, delete item etc
   const [itemForm, setItemForm] = useState<any>({})
   const [itemImages, setItemImages] = useState<any>([])

   const [tmpColumnList, setTmpColumnList] = useState<string[]>([])

   const [currentSkipValue, setCurrentSkipValue] = useState<number>(
      PAGINATION.skip
   )
   const [currentTakeValue, setCurrentTakeValue] = useState<number>(
      PAGINATION.take
   )
   const [defaultHeaderSortBy, _setDefaultHeaderSortBy] =
      useState<string>('title')
   const [activeSortHeader, setActiveSortHeader] = useState<SortObject>({
      sortBy: 'title',
      sortDirection: SortDirection.ASCENDING,
   })

   const [isBarcodePrompt, setIsBarcodePrompt] = useState(false)
   const [barcodeError, setBarcodeError] = useState<string>('')
   const [barcodeInvaidInput, setBarcodeInvalidInput] = useState<string>('')
   const [isItemLimitPrompt, setIsItemLimitPrompt] = useState(false)

   const updateItem = (obj: Item) => {
      const updateObject = prepareUpdateItemObject(obj)
      update(updateObject)
   }

   const addItem = (obj: Item) => add(obj)

   const removeItem = () => {
      remove({ id: itemId })
   }

   const copyItem = () => {
      add({
         ...itemForm,
         Id: null,
         ToolLoanId: 0,
         localUniqueId: null,
         LocationTrackingLabel: null,
         CustomRentalRate1: itemForm.CustomRentalRate1Value,
         CustomRentalRate2: itemForm.CustomRentalRate2Value,
         CustomRentalRate3: itemForm.CustomRentalRate3Value,
         CustomRentalRate4: itemForm.CustomRentalRate4Value,
         itemImages: itemImages,
      })
      setItemAction(null)
   }

   const manageKit = () => {
      setIsManageKitModalOpen(true)
      setIsModalVisible(false)
   }

   const {
      result: getItemKitData,
      addKit,
      getKits,
      deleteKit,
      isSuccessKits,
      isErrorCreate,
      isSuccessCreate,
      isErrorDeleteKit,
      isSuccessDeleteKit,
   } = useItemGetKits()

   const [isKitsToAddModal, isSetIsKitsToAdd] = useState(false)
   const [isKitsToDeleteModal, isSetIsKitsToDelete] = useState(false)
   const [addToKitQty, setAddToKitQty] = useState(1)

   const handleAddToKit = (obj: {}) => {
      // use this to post payload for AddToKit modal
      addKit(obj)
   }

   const resetSnackBarRefresh = () => {
      setIsVisibleSnackbar(false)

      const criteria = {
         query,
         ...filterValues,
      }

      refetchItemData()
      getCount(criteria)
   }

   const refetchFilterAndDropdownOptions = () => {
      refetchCategories()
      refetchManufacturers()
   }

   useEffect(() => {
      const checkoutOpts = isEmployeeUser
         ? employeeCheckoutOptions
         : companyCheckoutOptions
      setCheckoutOptions(checkoutOpts)
   }, [isEmployeeUser])

   useEffect(() => {
      // if itemform isnt empty then set selected item
      if (Object.keys(itemForm).length !== 0) {
         setSelectedItemsForWorkflow([itemForm])
      }
   }, [itemForm])

   useEffect(() => {
      // refetch any additional dropdown and filter options that may have been added here
      refetchFilterAndDropdownOptions()
   }, [])

   useEffect(() => {
      if (isSuccessCreate) {
         handleClose('avoidReset')
         setIsVisibleSnackbar(true)
         setMessage('Kit has been added')
         setSeverityStatus(Severity.SUCCESS)
         setTimeout(resetSnackBarRefresh, 2000)
      }
   }, [isSuccessCreate])

   useEffect(() => {
      if (isErrorCreate) {
         handleClose('avoidReset')
         setIsVisibleSnackbar(true)
         setMessage('An error happened when adding kit')
         setSeverityStatus(Severity.SUCCESS)
      }
   }, [isErrorCreate])

   useEffect(() => {
      if (isSuccessDeleteKit) {
         handleClose('avoidReset')
         setIsVisibleSnackbar(true)
         setMessage('Kit has been removed')
         setSeverityStatus(Severity.SUCCESS)
         setTimeout(resetSnackBarRefresh, 2000)
      }
   }, [isSuccessDeleteKit])

   useEffect(() => {
      if (isErrorDeleteKit) {
         handleClose('avoidReset')
         setIsVisibleSnackbar(true)
         setMessage('An error happened when deleting kit')
         setSeverityStatus(Severity.SUCCESS)
      }
   }, [isErrorDeleteKit])

   const handleDeleteKit = (obj: {}) => {
      // use this to post payload for delete kit modal
      deleteKit(obj)
   }

   const addToKit = () => {
      handleClose('avoidReset')
      setPromptTitle('Add to Kit')
      isSetIsKitsToAdd(true)

      // 1) get kits for add it kit

      getKits({
         defaultLocationID: itemForm.DefaultLocationID,
         status: itemForm.Status,
         assignedToUserID: itemForm.AssignedToUserID,
         requestedLocationId: itemForm.RequestedLocationId,
         locationID: itemForm.LocationID,
         skip: null,
         take: null,
         query: null,
      })
   }

   const [itemKitOption, setItemKitOptions] = useState([])
   useEffect(() => {
      if (getItemKitData.data) {
         setItemKitOptions(convertNoteTypesToOptions(getItemKitData.data))
      }
   }, [isSuccessKits, getItemKitData])

   const removeFromKit = (id: number) => {
      handleClose('avoidReset')
      setPromptTitle('Remove from Kit')
      isSetIsKitsToDelete(true)
   }

   const handleBarcodeCheck = async (
      action: number,
      id?: number,
      obj?: Item
   ) => {
      const barcode = itemForm?.Barcode
      const excludeItemId = obj?.Id
      if (action !== Actions.Delete && barcode) {
         await onSubmitBarcodelookup(barcode, excludeItemId).then((data) => {
            if (data.response) {
               setIsBarcodePrompt(true)
               setBarcodeInvalidInput(barcode)
               setBarcodeError(data.ErrorMessage)
               return
            } else {
               handleActionForm(action, id, obj)
            }
         })
      } else {
         handleActionForm(action, id, obj)
      }
   }

   const handleActionForm = (action: number, id?: number, obj?: Item) => {
      setIsVisibleSnackbar(false)
      switch (action) {
         case Actions.Add:
            const objectToAdd = {
               ...obj,
               ManufacturerId: itemForm?.Manufacturer ? '' : itemManufacturerId,
               Manufacturer: itemForm?.Manufacturer || 'Unspecified',
               Category: itemForm?.Category || 'Uncategorized',
            }
            addItem(objectToAdd)
            break
         case Actions.Copy:
            copyItem()
            break
         case Actions.Edit:
            updateItem({
               Id: itemId,
               ManufacturerId: itemManufacturerId || 0,
               Title: itemTitle,
               updateLocation: true,
               Category: itemCategory,
               ...obj,

               // if value exists and rate is null then set value
               CustomRentalRate1:
                  obj.CustomRentalRate1Value && obj.CustomRentalRate1 === null
                     ? obj.CustomRentalRate1Value
                     : obj.CustomRentalRate1,
               CustomRentalRate2:
                  obj.CustomRentalRate2Value && obj.CustomRentalRate2 === null
                     ? obj.CustomRentalRate2Value
                     : obj.CustomRentalRate2,
               CustomRentalRate3:
                  obj.CustomRentalRate3Value && obj.CustomRentalRate3 === null
                     ? obj.CustomRentalRate3Value
                     : obj.CustomRentalRate3,
               CustomRentalRate4:
                  obj.CustomRentalRate4Value && obj.CustomRentalRate4 === null
                     ? obj.CustomRentalRate4Value
                     : obj.CustomRentalRate4,
            })
            break
         case Actions.Delete:
            removeItem(id)
            break
      }
   }

   const [isDeletePrompt, setIsDeletePrompt] = useState<boolean>(false)
   const [isCopyPrompt, setIsCopyPrompt] = useState<boolean>(false)
   const [isNewItemNote, setIsNewItemNote] = useState<boolean>(false)
   const [promptTitle, setPromptTitle] = useState<string>('')

   useEffect(() => {
      return isBarcodePrompt ? disableKeyboardInput() : enableKeyboardInput()
   }, [isBarcodePrompt])

   const handleClose = (arg?: string) => {
      setIsModalVisible(false)
      setIsNewItemNote(false)
      setActiveValue(defaultActiveValue)
      isSetIsKitsToAdd(false)
      isSetIsKitsToDelete(false)
      if (arg !== 'avoidReset') resetRef?.current?.click()
      setItemAction(null)
      setItemStatus(0)
   }

   const handleCopyPrompt = () => {
      setIsCopyPrompt(true)
      handleClose()
   }

   const handleRemovePrompt = () => {
      handleClose()
      setIsDeletePrompt(true)
   }

   const handleNote = (id: number) => {
      setItemStatus(4) //TODO: create an enum from statuses 4 relates to notes
      setIsNewItemNote(true)
   }

   const handleRemove = (id) => {
      setIsDeletePrompt(false)
      removeItem(id)
   }

   const handleCopy = (arg) => {
      setItemForm({
         ...itemForm,
         Title: `${itemForm.Title} (Copy)`,
         ManufacturerId: itemForm.ManufacturerId,
         copyId: itemForm.Id,
         SerialNumber: '',
         Barcode: '',
         itemImages: itemImages,
      })

      setItemAction(arg)
      setIsCopyPrompt(false)
      setIsModalVisible(true)
   }

   const handleCommenceWorkflow = () => {
      if (isQuantityModalOpen) {
         setIsQuantityModalOpen(false)
      }
      setIsCheckoutModalOpen(true)
   }

   const handleResetPageAfterCheckout = () => {
      handleClose('avoidReset')
      refetchItemData()
      setIsCheckoutModalOpen(false)
      setIsQuantityModalOpen(false)
   }

   const isQuantityItemsSelected = (array: Item[]) => {
      return array.some(
         (x) =>
            (x.ToolType == ToolTypes['Consumable'] ||
               x.ToolType == ToolTypes['Quantity Item']) &&
            x.StatusQuantity > 1
      )
   }

   const handleIncrementDecrementQuantity = (item: Item, val: string) => {
      const itemToAdjust = selectedItemsForWorkflow.find(
         (y) => y.localUniqueId === item.localUniqueId
      )
      if (val === 'dec' && itemToAdjust.quantityForCart > 1) {
         itemToAdjust.quantityForCart = itemToAdjust.quantityForCart - 1
      } else if (
         val === 'inc' &&
         itemToAdjust.quantityForCart < itemToAdjust.StatusQuantity
      ) {
         itemToAdjust.quantityForCart = itemToAdjust.quantityForCart + 1
      } else {
         return
      }
      setSelectedItemsForWorkflow([...selectedItemsForWorkflow])
   }

   const handleCustomTotalChange = (e: any, item: Item) => {
      e.target.value = parseInt(e.target.value)
      const itemToAdjust = selectedItemsForWorkflow.find(
         (y) => y.localUniqueId === item.localUniqueId
      )
      const adjustedValue =
         e.target.value === '' ? e.target.value : Number(e.target.value)
      // @ts-ignore
      itemToAdjust.quantityForCart = adjustedValue
      setSelectedItemsForWorkflow([...selectedItemsForWorkflow])
   }

   const handleWorkflowAction = (workflowOption: string) => {
      let workflowPath
      if (workflowOption === 'Transfer') {
         if (selectedItemsForWorkflow[0].StatusDesc === 'Available') {
            workflowPath = checkoutOptions.find(
               (x) => x.label === 'Warehouse Transfer'
            )
         } else {
            workflowPath = checkoutOptions.find(
               (x) => x.label === 'Field Transfer'
            )
         }
      } else {
         workflowPath = checkoutOptions.find((x) => x.label === workflowOption)
      }
      setCheckoutAction(workflowPath)

      if (isQuantityItemsSelected(selectedItemsForWorkflow)) {
         let quantItems = selectedItemsForWorkflow
         //  setSelectedWorkflowActionForQuantityModal(() => selectedWorkflowAction); // handoff workflow action to quantity modal
         // there should only ever be one item in this array
         if (
            workflowPath.label === 'Warehouse Transfer' &&
            workflowPath.status === ToolStatus.AVAILABLE
         ) {
            quantItems[0].quantityForCart = quantItems[0].StatusQuantity
         } else {
            quantItems[0].quantityForCart = 1
         }
         setSelectedItemsForWorkflow(quantItems)
         setIsQuantityModalOpen(true)
      } else {
         handleCommenceWorkflow()
      }
   }

   const allItemButtons: ItemButton[] = [
      {
         name: 'actionLoan',
         title: 'Loan',
         action: () => handleWorkflowAction('Loan'),
         type: 'Loan',
      },
      {
         name: 'actionLoan',
         title: 'Borrow',
         action: () => handleWorkflowAction('Borrow'),
         type: 'Borrow',
      },
      {
         name: 'actionTransfer',
         title: 'Transfer',
         action: () => handleWorkflowAction('Transfer'),
         type: 'TransferTo',
      },
      {
         name: 'actionReturn',
         title: 'Return',
         action: () => handleWorkflowAction('Return'),
         type: 'CheckIn',
      },
      {
         name: 'copy',
         title: 'Copy',
         action: (obj: {}) =>
            profile.AllowAddTool
               ? handleCopyPrompt()
               : setIsItemLimitPrompt(true),
         overrideViewBox: '-.75,.55,5,7',
         type: '',
      },
      {
         name: 'remove',
         title: 'Delete',
         action: (id: number) => handleRemovePrompt(),
         overrideViewBox: '.75,.75,3,5',
         type: 'DeleteTool',
      },
      {
         name: 'add-note',
         title: 'Note',
         action: (id: number) => handleNote(id),
         type: 'AddNote',
      },
      {
         name: 'manage-kit',
         title: 'Manage Kit',
         action: (id: number) => manageKit(),
         type: 'AddtokitOpenItems',
      },
      {
         name: 'add-to-kit',
         title: 'Add to Kit',
         action: () => addToKit(),
         type: 'AddtokitOpenKit',
      },
      {
         name: 'remove-from-kit',
         title: 'Remove from Kit',
         action: (id: number) => removeFromKit(id),
         type: 'RemoveFromKit',
      },
   ]

   const showActionForm = (
      action: number | ((prevState: number) => number),
      id?: number,
      title?: string,
      manufacturerId?: number,
      category?: string,
      itemListData = []
   ) => {
      const obj = itemListData
      setItemId(id)
      setItemAction(action)
      setItemTitle(title)
      setItemManufacturerId(manufacturerId)
      setItemCategory(category)

      if (action === Actions.Delete || action === Actions.Edit) {
         setActiveValue(obj)
         setItemForm(obj)
         setItemImages([])
      }

      // setActionState(action)
      if (!bypass || action === Actions.Add) {
         setIsModalVisible(true)
      }

      if (action === Actions.Add) {
         setItemImages([])
         setItemForm({})
      }

      setBypass(false)

      itemNotesList(String(id))
      getItemHistoryData(String(id))
   }

   const handleSortReset = () => {
      sortRef.current.reset()
      handleHeaderSortClick(defaultHeaderSortBy, SortDirection.ASCENDING)
      setIsSortPanelOpen(false)
   }

   const handleHeaderSortClick = (
      sortBy: string,
      sortDirection: SortDirection
   ) => {
      handleGetItems(
         searchObj?.query,
         currentSkipValue,
         currentTakeValue,
         savedFilters,
         {
            sortBy,
            sortDirection,
         }
      )

      if (isSortPanelOpen) {
         setIsSortPanelOpen(false)
      }
      setActiveSortHeader({
         sortBy,
         sortDirection,
      })
   }

   const handleGetItems = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: FiltersObject,
      sortObject?: SortObject // will only have sort object if being called from handleHeader sort
   ) => {
      const formattedFilters = formatFiltersForAPI({
         ...(filters || savedFilters),
      })

      setFilterValues(formattedFilters)
      setCurrentSkipValue(skip)
      setCurrentTakeValue(take)

      const criteria = {
         skip,
         take,
         query,
         ...formattedFilters,
         ...(sortObject ? sortObject : activeSortHeader),
      }
      list(criteria)

      getCount(criteria)
   }

   const applySavedFilters = (filters: FiltersObject) => {
      setFiltersOpen(false)
      handleGetItems(searchObj?.query, 0, pageSized, filters)
      setFilterValues(formatFiltersForAPI(filters))
      setIsPageReset(true)
   }

   const handleFilterByToolStatus = (
      status: number,
      itemTypes?: {
         label: string
         value: FilterValue
      }[]
   ) => {
      // if someone navigates from dashboard total tiles we want to add a few filters
      const toolStatus = status === 99 ? 1 : status

      modifyMulti([
         {
            name: 'toolStatus',
            label: 'tabs',
            value: (toolStatus || 0).toString(),
         },
         ...(itemTypes?.length
            ? [
                 {
                    name: 'itemTypes',
                    label: '',
                    value: itemTypes || savedFilters?.itemTypes?.value || [],
                 },
              ]
            : []),
      ])
   }

   let [throttleTimer] = useState(5000)
   let [throttleInterval] = useState(1000)
   let [searchObj, setSearchObj] = useState<SearchObject>({})

   const handleSearch = (
      query?: string,
      skip?: number,
      take?: number,
      filters?: {}
   ) => {
      const formattedFilters = formatFiltersForAPI(filters || savedFilters)

      setFilterValues({ ...formattedFilters, query })

      const criteria = { query, skip, take, ...formattedFilters }

      setSearchObj(criteria)
      list(criteria)

      getCount(criteria)
      setIsPageReset(true)
   }

   const onKeyUp = () => {
      clearTimeout(throttleTimer)
      //@ts-ignore
      throttleTimer = setTimeout(throttleCompleted, throttleInterval)
   }

   const onKeyDown = () => {
      clearTimeout(throttleTimer)
   }

   const throttleCompleted = () => {
      const { query, skip } = searchObj
      handleGetItems(query, skip, pageSized, savedFilters)
   }

   const {
      data: itemNotesData,
      itemNotesList,
      refetch: refetchItemNotes,
      isRefetching: isRefetchingNotes,
   } = useItemNotes()
   const {
      data: itemHistoryData,
      getItemHistoryData,
      refetch: refetchItemHistory,
      isRefetching: isRefetchingHistory,
   } = useItemHistory()

   const {
      data: listViewData,
      getItemView,
      isLoading: isListLoading,
      isRefetching: isListRefetching,
      isSuccess: isListSuccess,
   } = useItemsView()

   const showDownloadModal = () => {
      setIsExportModalVisible(true)
   }

   const handleExportClose = () => {
      setIsExportModalVisible(false)
      buttonRef.current.click()
   }

   const handleDropdownChange = (
      name: string,
      label: string,
      options: Option[]
   ) => {
      const filterOptions = Array.isArray(options)
         ? options?.map((option) => ({
              label: option.label,
              value: option.value,
           }))
         : [options]

      modify(name, label, filterOptions)
   }

   const handleRemoveTag = (name: string, id: string): void => {
      const newFilters = removeFilter(name, id)
      applySavedFilters(newFilters)
      setFilterValues(formatFiltersForAPI(newFilters))
   }

   const handleSelectChange = (event: ChangeEvent<HTMLFormElement>) => {
      const { name, value } = event.target
      modify(name, '', value)
   }

   const handleFilterSave = async () => {
      const isSaved = await save()

      if (isSaved) {
         applySavedFilters(tmpFilters)
      }
   }

   const handleResetFilters = () => {
      formRef.current.reset()
      reset()
      applySavedFilters({})
      setIsPageReset(true)
   }

   const [fields, setFields] = useState([])

   const initialState = { hiddenColumns: fields }

   const fieldsLabelArr = [
      { CustomDate1Label: CustomDate1Enabled },
      { CustomDate2Label: CustomDate2Enabled },
      { CustomText1Label: CustomText1Enabled },
      { CustomText2Label: CustomText2Enabled },
      { CustomURL1Label: CustomURL1Enabled },
      { CustomURL2Label: CustomURL2Enabled },
      { CustomRentalRate1Label: CustomRentalRate1Enabled },
      { CustomRentalRate2Label: CustomRentalRate2Enabled },
      { CustomRentalRate3Label: CustomRentalRate3Enabled },
      { CustomRentalRate4Label: CustomRentalRate4Enabled },
      { AssignedToKit: KitEnabled },
   ]

   const hideFields = () => {
      let arr = []

      const fieldsValueArr = [
         CustomDate1Label,
         CustomDate2Label,
         CustomText1Label,
         CustomText2Label,
         CustomURL1Label,
         CustomURL2Label,
         CustomRentalRate1Label,
         CustomRentalRate2Label,
         CustomRentalRate3Label,
         CustomRentalRate4Label,
      ]

      fieldsLabelArr.forEach((item, index) => {
         if (!Object.values(item).shift()) {
            arr.push(
               Object.keys(item).shift().replace('Label', 'Value'),
               fieldsValueArr[index]
            )
         }
      })
      if (!settings.EnableLocationTracking)
         arr = [...arr, ...locationTrackingArr]
      if (!settings.AuditEnabled) arr = [...arr, ...auditArr]
      if (!settings.QuantityEnabled) arr = [...arr, ...quantityArr]

      setFields(arr)
   }

   useEffect(() => {
      hideFields()
   }, [
      settings.EnableLocationTracking,
      settings.AuditEnabled,
      settings.QuantityEnabled,
   ])

   const columns = useMemo(
      () => [
         {
            Header: 'Pic',
            accessor: 'PictureURL',
            id: 'PictureURL',
            allowBlank: true,
            Cell: (cell: { row: { original: { PictureURL: string } } }) => (
               <Image
                  url={cell.row.original.PictureURL}
                  alt={cell.row.original.PictureURL}
                  defaultImagePadding={3}
                  width={25}
               />
            ),
            maxWidth: 65,
         },
         {
            headerTitle: 'Item Name',
            Header: () => (
               <TableSortHeader
                  header={'Item Name'}
                  id={'Title'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'Title',
            id: 'Title',
            width: 200,
            minWidth: 20,
         },
         {
            Cell: (cell: { row: { original: { Title: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Title}
               </div>
            ),
         },
         {
            headerTitle: 'Type',
            Header: () => (
               <TableSortHeader
                  header="Type"
                  id={'ToolType'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'ToolType',
            id: 'ToolType',
            width: 150,
            minWidth: 20,
            Cell: ({ row }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {ToolTypes[row?.original?.ToolType]}
               </div>
            ),
         },
         {
            Header: 'Assigned to Kit',
            accessor: 'AssignedToKitName',
            id: 'AssignedToKitName',
            width: 150,
            minWidth: 20,
            Cell: (cell: {
               row: { original: { AssignedToKitName: string } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.AssignedToKitName}
               </div>
            ),
         },
         {
            Header: 'Total Qty',
            id: 'Quantity',
            accessor: 'TotalQuantityForGrid',
            width: 65,
            minWidth: 20,
            align: 'center',
         },
         {
            Header: 'Status Qty',
            accessor: 'StatusQuantity',
            id: 'StatusQuantity',
            width: 65,
            minWidth: 20,
            align: 'center',
         },
         {
            headerTitle: 'Status',
            Header: () => (
               <TableSortHeader
                  header={'Status'}
                  id={'StatusDesc'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'StatusDesc',
            id: 'StatusDesc',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Loaned/Pending To',
            Header: () => (
               <TableSortHeader
                  header={'Loaned/Pending To'}
                  id={'AssignedToUser'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { AssignedToUser: string }) => {
               if (row.AssignedToUser === null) {
                  return ''
               }
               return row.AssignedToUser
            },
            id: 'AssignedToUser',
            width: 200,
            minWidth: 20,
         },
         {
            Header: 'Item Detail',
            accessor: 'Description',
            Cell: (cell: { row: { original: { Description: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Description}
               </div>
            ),
            id: 'Description',
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Manufacturer',
            Header: () => (
               <TableSortHeader
                  header={'Manufacturer'}
                  id={'Manufacturer'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'Manufacturer',
            Cell: (cell: { row: { original: { Manufacturer: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.Manufacturer}
               </div>
            ),
            id: 'Manufacturer',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Model No.',
            Header: () => (
               <TableSortHeader
                  header={'Model No.'}
                  id={'ModelNumber'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'ModelNumber',
            id: 'ModelNumber',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Serial No.',
            accessor: 'SerialNumber',
            id: 'SerialNumber',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Barcode',
            Header: () => (
               <TableSortHeader
                  header={'Barcode'}
                  id={'Barcode'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'Barcode',
            id: 'Barcode',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Category',
            accessor: 'Category',
            id: 'Category',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Purchase Date',
            Header: () => (
               <TableSortHeader
                  header={'Purchase Date'}
                  id={'PurchaseDate'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { PurchaseDate: string }) => {
               return removeMinYear(formatUsDate(row.PurchaseDate))
            },
            width: 150,
            minWidth: 20,
            id: 'PurchaseDate',
         },
         {
            headerTitle: 'Purchase Price',
            Header: () => (
               <TableSortHeader
                  header={'Purchase Price'}
                  id={'PurchasePrice'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row) => formatPrice(row.PurchasePrice || 0),
            id: 'PurchasePrice',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Total Value',
            accessor: (row) => formatPrice(row.TotalValue),
            id: 'TotalValue',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Vendor',
            accessor: 'Vendor',
            id: 'Vendor',
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Warranty Date',
            Header: () => (
               <TableSortHeader
                  header={'Warranty Date'}
                  id={'WarrantyDate'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { WarrantyDate: string }) => {
               if (isNaN(parseInt(row.WarrantyDate))) {
                  return ''
               }
               return removeMinYear(formatUsDate(row.WarrantyDate))
            },
            id: 'WarrantyDate',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Rental Item',
            Header: () => (
               <TableSortHeader
                  header={'Rental Item'}
                  id={'RentalItem'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { IsRentalTool: boolean }) =>
               row.IsRentalTool ? 'rented' : 'not rented',
            id: 'RentalItem',
            width: 150,
            minWidth: 20,
         },
         {
            Header: 'Rental Start Date',
            accessor: (row: { RentalDate: string }) => {
               return removeMinYear(formatUsDate(row.RentalDate))
            },
            id: 'RentalDate',
            width: 110,
            minWidth: 20,
         },
         {
            Header: 'Rental End Date',
            accessor: (row: { RentalReturnDate: string }) => {
               return removeMinYear(formatUsDate(row.RentalReturnDate))
            },
            id: 'RentalReturnDate',
            width: 110,
            minWidth: 20,
         },
         {
            Header: settings.CustomRentalRate1Label,
            accessor: (row) => formatPrice(row.CustomRentalRate1),
            id: 'CustomRentalRate1',
            width: 150,
            minWidth: 20,
         },

         {
            Header: settings.CustomRentalRate2Label,
            accessor: (row) => formatPrice(row.CustomRentalRate2),
            id: 'CustomRentalRate2',
            width: 150,
            minWidth: 20,
         },

         {
            Header: settings.CustomRentalRate3Label,
            accessor: (row) => formatPrice(row.CustomRentalRate3),
            id: 'CustomRentalRate3',
            width: 150,
            minWidth: 20,
         },

         {
            Header: settings.CustomRentalRate4Label,
            accessor: (row) => formatPrice(row.CustomRentalRate4),
            id: 'CustomRentalRate4',
            width: 150,
            minWidth: 20,
         },
         {
            Header: settings.CustomDate1Label,
            accessor: (row) => {
               return removeMinYear(formatUsDate(row.CustomDate1Value))
            },
            id: 'CustomDate1Value',
            width: 150,
            minWidth: 20,
         },
         {
            Header: settings.CustomDate2Label,
            accessor: (row) => {
               return removeMinYear(formatUsDate(row.CustomDate2Value))
            },
            id: 'CustomDate2Value',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: settings.CustomText1Label,
            Header: () => (
               <TableSortHeader
                  header={settings.CustomText1Label}
                  id={'CustomText1Value'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row) => row.CustomText1Value,
            id: 'CustomText1Value',
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: settings.CustomText2Label,
            Header: () => (
               <TableSortHeader
                  header={settings.CustomText2Label}
                  id={'CustomText2Value'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row) => row.CustomText2Value,
            id: 'CustomText2Value',
            width: 200,
            minWidth: 20,
         },
         {
            Header: settings.CustomURL1Label,
            accessor: 'CustomURL1Value',
            Cell: (cell: {
               row: { original: { CustomURL1Value: string } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  <a href={cell.row.original.CustomURL1Value} target={'_blank'}>
                     {cell.row.original.CustomURL1Value}
                  </a>
               </div>
            ),
            id: 'CustomURL1Value',
            width: 200,
            minWidth: 20,
         },
         {
            Header: settings.CustomURL2Label,
            accessor: 'CustomURL2Value',
            id: 'CustomURL2Value',
            Cell: (cell: {
               row: { original: { CustomURL2Value: string } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  <a href={cell.row.original.CustomURL2Value} target={'_blank'}>
                     {cell.row.original.CustomURL2Value}
                  </a>
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
         {
            Header: 'WebSite URL',
            accessor: 'WebSiteURL',
            id: 'WebSiteURL',
            Cell: (cell: { row: { original: { WebSiteURL: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  <a href={cell.row.original.WebSiteURL} target={'_blank'}>
                     {cell.row.original.WebSiteURL}
                  </a>
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
         {
            Header: 'Owners URL',
            accessor: 'AttachmentUrl',
            id: 'AttachmentUrl',
            Cell: (cell: { row: { original: { AttachmentUrl: string } } }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  <a href={cell.row.original.AttachmentUrl} target={'_blank'}>
                     {cell.row.original.AttachmentUrl}
                  </a>
               </div>
            ),
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Return Warehouse',
            Header: () => (
               <TableSortHeader
                  header={'Return Warehouse'}
                  id={'DefaultLocation'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row) => row.DefaultLocation,
            Cell: (cell: {
               row: { original: { DefaultLocation: string } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.DefaultLocation}
               </div>
            ),
            id: 'DefaultLocation',
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Current/Pending Location',
            Header: () => (
               <TableSortHeader
                  header={'Current/Pending Location'}
                  id={'CurrentLocation'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row) => row.CurrentLocation,
            Cell: (cell: {
               row: { original: { CurrentLocation: string } }
            }) => (
               <div className={tableStyles.tableColumnCellWrapper}>
                  {cell.row.original.CurrentLocation}
               </div>
            ),
            id: 'CurrentLocation',
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Last GPS Scan',
            Header: () => (
               <TableSortHeader
                  header={'Last GPS Scan'}
                  id={'GpsCreatedOn'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { GPS: GpsScan }) => {
               if (row.GPS === null) {
                  return ''
               }
               return formatLocaleDate(row.GPS?.CreatedOn)
            },
            id: 'GpsCreatedOn',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Last Scanned By',
            Header: () => (
               <TableSortHeader
                  header={'Last Scanned By'}
                  id={'GpsCreatedByName'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { GPS: GpsScan }) => {
               if (row.GPS === null) {
                  return ''
               }
               return row.GPS?.CreatedByName
            },
            id: 'GpsCreatedByName',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Last GPS Location Name',
            Header: () => (
               <TableSortHeader
                  header={'Last GPS Location Name'}
                  id={'GPSLocationName'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { GPS: GpsScan }) => {
               if (row.GPS === null) {
                  return ''
               }
               return row.GPS?.GPSLocationName
            },
            id: 'GPSLocationName',
            width: 200,
            minWidth: 20,
         },
         {
            Header: 'Last Audit Date',
            accessor: (row: { LastAuditDate: string }) => {
               return removeMinYear(formatUsDate(row.LastAuditDate))
            },
            id: 'LastAuditDate',
            width: 150,
            minWidth: 20,
         },
         {
            headerTitle: 'Last Audit Condition',
            Header: () => (
               <TableSortHeader
                  header={'Last Audit Condition'}
                  id={'LastAuditConditionDesc'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: 'LastAuditConditionDesc',
            id: 'LastAuditConditionDesc',
            width: 200,
            minWidth: 20,
         },
         {
            headerTitle: 'Created Date',
            Header: () => (
               <TableSortHeader
                  header={'Created Date'}
                  id={'CreatedDate'}
                  activeSortHeader={activeSortHeader}
                  handleHeaderSortClick={handleHeaderSortClick}
               />
            ),
            accessor: (row: { CreatedDate: string }) => {
               return removeMinYear(formatUsDate(row.CreatedDate))
            },
            id: 'CreatedDate',
            width: 150,
            minWidth: 20,
         },
      ],
      [
         itemData?.length,
         tableColumnOptionsLoading,
         settings,
         activeSortHeader,
         currentSkipValue,
         currentTakeValue,
         savedFilters,
         tmpFilters,
      ]
   )

   const [itemStatus, setItemStatus] = useState<number>(0)

   useEffect(() => {
      // user clicked dashboard total button
      //@ts-ignore
      const toolStatus = location?.state?.status || null
      //@ts-ignore
      const itemTypes = location?.state?.itemTypes || null

      const itemTypeIds = itemTypes?.map((itemType) => itemType.value) || []

      const criteria = {
         skip,
         take,
         query,
         ...(toolStatus
            ? {
                 itemTypes: itemTypeIds,
                 toolStatus,
              }
            : {}),
      }

      getCount(criteria)

      if (toolStatus) {
         handleFilterByToolStatus(toolStatus, itemTypes)
      }

      setPath(crudPaths, key)
      setCountPath(crudPaths, 'totalCount')
      setPageOptions(criteria)
   }, [])

   useEffect(() => {
      if (tmpFilters?.toolStatus?.label === 'tabs') {
         handleFilterSave()
      }
   }, [tmpFilters?.toolStatus?.value, tmpFilters?.toolStatus?.label])

   const [hidePagination, setHidePagination] = useState(false)
   const [bypass, setBypass] = useState(true)

   const handleView = (arg: number) => {
      setHidePagination(arg === Views.Map)
      if (arg === 1) setBypass(true)
      setView(arg)
      setIsSortPanelOpen(false)
   }

   const handleReorderColumns = (newColumns) => {
      setTmpColumnList(newColumns)
   }

   const handleResetColumns = () => {
      saveTableColumnOptions([])
      setColumnSettingsOpen(false)
   }

   const handleSaveColumnReorder = () => {
      setColumnSettingsOpen(false)
      saveTableColumnOptions(tmpColumnList)
   }

   const handleSuccessfulImport = () => {
      const criteria = {
         query,
         ...filterValues,
      }
      refetchItemData()
      getCount(criteria)
      resetImportItems()
      setIsImportModalVisible(false)
   }

   const handleCloseImportModal = () => {
      resetImportItems()
      setIsImportModalVisible(false)
   }

   const itemTypeOptions = convertItemTypesToOptions(itemTypes)
   const itemStatusOptions = convertItemStatusStatesToOptions(
      !isEmployeeUser ? radioToolStatus : radioToolStatusEmployeeUser
   )
   const connectionOptions = convertConnectionsToOptions(connections)
   const conditionOptions = convertConditionsToOptions(conditions)
   const locationOptions = convertLocationToOptions(activeLocations)
   const locationActiveOptions = convertLocationToOptions(activeWarehouse)
   const manufacturerOptions = convertManufacturersToOptions(manufacturers)
   const categoryOptions = convertCategoriesToOptions(categories)
   const tableSortColumnOptions = convertTableSortColumnOptionsListToOptions(
      tableSortColumnOptionsList
   )

   const orderedColumns = useMemo(() => {
      const columnList = itemTableOptions?.length
         ? itemTableOptions
         : defaultColumnOrder
      return renderOrderedTableColumns(columns, columnList)
   }, [itemTableOptions, settings])

   const defaultColumnItems = useMemo(
      () => generateColumnItemOptions(defaultColumnOrder, columns),
      [settings]
   )
   const currentColumnItems = useMemo(
      () =>
         generateColumnItemOptions(
            itemTableOptions ? itemTableOptions : defaultColumnOrder,
            columns
         ),
      [settings]
   )

   const getItem = (
      Id: number,
      ToolLoanId: number | unknown = null,
      KitId: number
   ) => {
      getItemView({ Id, ToolLoanId, KitId })
   }

   // post images
   const postItem = (item: any, localUniqueId) => {
      const formData: any = new FormData()
      formData.append('toolId', localUniqueId)
      formData.append('position', item.Position)
      formData.append('file[0]', item['File[0]'])
      formData.append('file[1]', item['File[1]'])
      upload(formData)
   }

   const uploadImages = (localUniqueId) => {
      itemImages
         .filter((image) => image.justAdded)
         .forEach((image, index) => {
            postItem(image, localUniqueId)
         })
   }

   useEffect(() => {
      const toolStatus = location?.state?.status || null
      const itemTypes = location?.state?.itemTypes || null
      // const statusTabId = toolStatus === 99 ? 1 : toolStatus;

      const itemTypeIds = itemTypes?.map((itemType) => itemType.value) || []

      const criteria = {
         skip,
         take: pageSized,
         query,
         ...(toolStatus
            ? {
                 itemTypes: itemTypeIds,
                 toolStatus,
              }
            : {}),
      }

      getCount(criteria)
      // upload images once new tool item created and at least one new image has been added
      if (msg !== null) {
         if (itemImages.length && itemImages.some((image) => image.justAdded)) {
            uploadImages(msg?.data?.Data.Id)
            refetchItemNotes()
         } else {
            refetchItemNotes()
            refetchItemData()
         }
      }
   }, [msg])

   useEffect(() => {
      // check upload images has completed before refetching itemData
      if (!isImporting) {
         refetchItemData()
      }
   }, [isImporting])

   useEffect(() => {
      if (itemAction === 3) {
         setItemForm(createDefaultSchema)
      }
   }, [itemAction])

   useEffect(() => {
      setItemImages([])
   }, [])

   useEffect(() => {
      //handles setting the form
      if (listViewData?.data?.Data && isListSuccess) {
         let data = listViewData?.data?.Data
         showActionForm(
            itemAction ? itemAction : Actions.Edit,
            data?.Id,
            data?.Title,
            data?.ManufacturerId,
            data?.Category,
            data
         )
      }
   }, [listViewData, isListSuccess])

   useEffect(() => {
      if (isErrorUpdate) {
         setMessage(errorUpdate)
         setSeverityStatus(Severity.ERROR)
         setIsVisibleSnackbar(true)
      }
   }, [isErrorUpdate])

   useEffect(() => {
      if (isSuccessUpdate) {
         setIsModalVisible(false)
         setItemAction(null)
      }
   }, [isSuccessUpdate])

   useEffect(() => {
      if (isErrorAdd) {
         setIsVisibleSnackbar(true)
         setSeverityStatus(Severity.ERROR)
         setMessage(error)
         setIsModalVisible(true)
      }
   }, [isErrorAdd])

   useEffect(() => {
      if (isSuccessAdd) {
         setIsModalVisible(false)
         setItemAction(null)
      }
   }, [isSuccessAdd])

   useEffect(() => {
      // need to refresh profile to get updated AllowAddTool value from API
      refreshProfile()
   }, [totalCount])

   const [deleteCompleted, setDeleteCompleted] = useState<boolean>(true)

   useEffect(() => {
      if (isDeleteSuccess) {
         setDeleteCompleted(true)
      }
   }, [isDeleteSuccess])

   const itemButtons: ItemButton[] = useMemo(() => {
      let buttonsToReturn = allItemButtons
      if (itemAction === Actions.Edit || itemAction === Actions.Readonly) {
         // cant copy an item if not allowed to add tools
         if (!profile?.AllowAddTool && isEmployeeUser) {
            buttonsToReturn = buttonsToReturn.filter((ib) => ib.name !== 'copy')
         }
         // unavailabe items cant be transferred
         if (!itemForm?.IsAvailable) {
            buttonsToReturn = buttonsToReturn.filter(
               (btn) => btn.name !== 'actionTransfer'
            )
         }
         // add item -> no buttons
      } else {
         buttonsToReturn = []
      }

      return buttonsToReturn
   }, [itemAction, itemForm, profile?.AllowAddTool])

   useEffect(() => {
      switch (itemStatus) {
         case 4:
            return refetchItemNotes()
         case 5:
            return refetchItemHistory()
         default:
            return
      }
   }, [itemStatus])

   const handleAddButtonClick = () =>
      profile.AllowAddTool
         ? showActionForm(Actions.Add, 0)
         : setIsItemLimitPrompt(true)

   const handleImportButtonClick = () =>
      profile.AllowAddTool
         ? setIsImportModalVisible(true)
         : setIsItemLimitPrompt(true)

   const [uploadModalView, setUploadModalView] = useState<ImportItemModalViews>(
      ImportItemModalViews.IMPORT_OPTIONS
   )

   // Upload items modal logic
   useEffect(() => {
      if (
         uploadModalView !== ImportItemModalViews.IMPORT_OPTIONS &&
         uploadModalView !== ImportItemModalViews.ERROR
      ) {
         const intervalId = setInterval(() => {
            setUploadPercentage((prevCount) => prevCount + 1)
         }, 200)

         // stop progress bar at 98 in case import has not completed
         if (uploadPercentage === 98) {
            return clearInterval(intervalId)
         }

         if (isImportItemsSuccess) {
            setUploadPercentage(100)
            return clearInterval(intervalId)
         }

         return () => clearInterval(intervalId)
      }
   }, [uploadPercentage, uploadModalView, isImportItemsSuccess])

   const showLoadingOverlay =
      isLoading ||
      isRefetching ||
      tableColumnOptionsLoading ||
      !deleteCompleted ||
      isUpdating ||
      isAdding ||
      isLoadingCategories ||
      isLoadingManufacturers ||
      isLoadingLocations ||
      isRefetchingHistory ||
      isRefetchingNotes

   return (
      <div>
         {showLoadingOverlay && <LoadingOverlay />}
         <SnackBar
            message={message}
            open={isVisibleSnackbar}
            onClose={() => closeSnackbar()}
            severity={severityStatus}
            disableAutoClose={false}
         />

         <PageHeader title={tableTitle}>
            {((profile?.AllowAddTool && isEmployeeUser) ||
               isAdminCompanyUser) && (
               <>
                  <Button
                     onClick={handleAddButtonClick}
                     preserveText
                     variant="plain"
                     minWidth="0"
                  >
                     <Svg id="plus" /> {addButtonText}
                  </Button>
                  <Button
                     onClick={handleImportButtonClick}
                     preserveText
                     variant="plain"
                     minWidth="0"
                  >
                     <ImportIcon /> Import Items
                  </Button>
               </>
            )}
            {isAdminCompanyUser && (
               <Button
                  onClick={showDownloadModal}
                  preserveText
                  variant="plain"
                  disabled={!Boolean(itemData.length)}
                  minWidth="0"
               >
                  <Svg id="download" width={8} height={8} /> {downloadCsvText}
               </Button>
            )}
         </PageHeader>

         <RadioButtonContainer variant="filter" id={''} groupName={''}>
            {quickFilterOptions.map(
               (item: { label: string; status: number }, index: number) => {
                  return (
                     <RadioButton
                        key={`radioIcon-${index}`}
                        id={`radioIcon-${index}`}
                        groupName="radioGroup"
                        variant="filter"
                        checked={
                           item.status.toString() ===
                           (savedFilters?.toolStatus?.value || '0')
                        }
                        action={() => handleFilterByToolStatus(item.status)}
                        disabled={isLoading}
                     >
                        {item.label}
                     </RadioButton>
                  )
               }
            )}
         </RadioButtonContainer>

         <div className={styles.searchWrapper}>
            <div className={styles.searchWrapperSearch}>
               <Search
                  handleQuery={handleSearch}
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  pageSized={pageSized}
                  placeHolder={placeholder}
                  setSearchCriteria={setSearchCriteria}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
               />
            </div>
            <RadioButtonContainer variant="icons" id={''} groupName={''}>
               {displayView.map(
                  (
                     item: { view: number; type: string; size?: number },
                     index: number
                  ) => {
                     return (
                        <RadioButton
                           key={index}
                           id={`radioIcon${index}`}
                           groupName="radioIconGroup"
                           variant="icon"
                           checked={index === 0}
                           action={() => handleView(item.view)}
                           disabled={isLoading}
                        >
                           <Svg
                              id={item.type}
                              width={item?.size || 28}
                              height={27}
                           />
                        </RadioButton>
                     )
                  }
               )}
            </RadioButtonContainer>

            {view === 2 && (
               <>
                  <div className={styles.searchWrapperFilter}>
                     <ColumnsButton
                        isActive={
                           itemTableOptions?.length &&
                           itemTableOptions !== defaultColumnOrder
                        }
                        onClick={() =>
                           setColumnSettingsOpen(!columnSettingsOpen)
                        }
                     />
                  </div>
               </>
            )}
            <div className={styles.searchWrapperFilter}>
               <SortButton
                  isActive={true} // TODO SET UP ACTIVE ICON WITH SORT COLUMN OPTIONS
                  onClick={() => setIsSortPanelOpen(!isSortPanelOpen)}
               />
            </div>

            <div className={styles.searchWrapperFilter}>
               <FilterButton
                  // filters will always be active as we're preselecting toolStatus
                  isActive={true}
                  onClick={() => setFiltersOpen(!filtersOpen)}
               />
            </div>
         </div>

         <FilterTags
            exclude={['toolStatus']}
            filters={savedFilters}
            onClick={handleRemoveTag}
         />

         {!isLoading && Array.isArray(itemData) && (
            <>
               {view === 3 ? (
                  <ItemMapView filters={filterValues} />
               ) : (
                  itemData &&
                  orderedColumns && (
                     <TableWrapper
                        isLoading={isLoading || isRefetching}
                        data={itemData}
                        columns={orderedColumns}
                        totalCount={totalCount}
                        getItems={handleGetItems}
                        takeItems={PAGINATION.take}
                        skipItems={PAGINATION.skip}
                        setSearchMode={setSearchMode}
                        searchMode={searchMode}
                        isPageReset={isPageReset}
                        setIsPageReset={setIsPageReset}
                        setPageSized={setPageSized}
                        extraClassName={extraClassName}
                        searchCriteria={searchCriteria}
                        viewValue={view}
                        setView={setView}
                        initialState={initialState}
                        toolStatus={savedFilters?.toolStatus?.value || 0}
                        hidePagination={hidePagination}
                        setBypass={setBypass}
                        listViewData={listViewData}
                        getItem={getItem}
                        isListLoading={isListLoading}
                        isListRefetching={isListRefetching}
                        isListSuccess={isListSuccess}
                     />
                  )
               )}

               <SlidePanel
                  isOpen={columnSettingsOpen}
                  onClose={() => setColumnSettingsOpen(false)}
               >
                  <Drawer
                     heading="Table Columns"
                     icon={
                        <BiColumns
                           style={{
                              marginRight: '0.5rem',
                              marginTop: '-0.5rem',
                           }}
                        />
                     }
                     onReset={handleResetColumns}
                     onSave={handleSaveColumnReorder}
                  >
                     <p>
                        Add or remove columns. To change the column order, drag
                        and drop a field.
                     </p>
                     <ItemReorder
                        defaultItems={defaultColumnItems}
                        items={
                           itemTableOptions?.length
                              ? currentColumnItems
                              : defaultColumnItems
                        }
                        onChange={handleReorderColumns}
                        permissions={{
                           AssignedToKitName: 'KitEnabled',
                           Quantity: 'QuantityEnabled',
                           StatusQuantity: 'QuantityEnabled',
                           CustomRentalRate1: 'CustomRentalRate1Enabled',
                           CustomRentalRate2: 'CustomRentalRate2Enabled',
                           CustomRentalRate3: 'CustomRentalRate3Enabled',
                           CustomRentalRate4: 'CustomRentalRate4Enabled',
                           CustomText1Value: 'CustomText1Enabled',
                           CustomText2Value: 'CustomText2Enabled',
                           CustomDate1Value: 'CustomDate1Enabled',
                           CustomDate2Value: 'CustomDate2Enabled',
                           CustomURL1Value: 'CustomURL1Enabled',
                           CustomURL2Value: 'CustomURL2Enabled',
                           DefaultLocation: 'EnableLocationTracking',
                           CurrentLocation: 'EnableLocationTracking',
                           LastAuditDate: 'AuditEnabled',
                           LastAuditConditionDesc: 'AuditEnabled',
                        }}
                     />
                  </Drawer>
               </SlidePanel>
               <ColumnSortPanel
                  isSortPanelOpen={isSortPanelOpen}
                  onClose={() => setIsSortPanelOpen(false)}
                  handleSortReset={handleSortReset}
                  handleApplySortOptions={handleHeaderSortClick}
                  sortRef={sortRef}
                  activeSortHeader={activeSortHeader}
                  columnOptions={tableSortColumnOptions}
               />
               <SlidePanel
                  isOpen={filtersOpen}
                  onClose={() => setFiltersOpen(false)}
               >
                  <Drawer
                     heading="Filters"
                     icon={
                        <Svg
                           id="filters"
                           overrideViewBox="0,0,8,8"
                           height="40"
                           width="40"
                        />
                     }
                     onReset={handleResetFilters}
                     onSave={handleFilterSave}
                  >
                     <form ref={formRef}>
                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.itemTypes?.value as Option[]
                           }
                           isMulti
                           id="itemTypes"
                           onChange={handleDropdownChange}
                           options={itemTypeOptions}
                           title="Type"
                        />

                        <Accordion title="Status">
                           <Select
                              error={errors?.toolStatus}
                              options={itemStatusOptions.map((status) => ({
                                 key: status.value,
                                 value: status.label,
                              }))}
                              id="toolStatus"
                              onChange={handleSelectChange}
                              value={tmpFilters?.toolStatus?.value as string}
                              isControlled
                              hideDefaultOption
                              noMargin
                           />
                        </Accordion>

                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.selectedConnections?.value as Option[]
                           }
                           isMulti
                           id="selectedConnections"
                           onChange={handleDropdownChange}
                           options={connectionOptions}
                           title="Connections"
                        />

                        {!!settings?.EnableLocationTracking && (
                           <DropdownFilter
                              defaultValue={
                                 tmpFilters?.selectedLocations
                                    ?.value as Option[]
                              }
                              isMulti
                              id="selectedLocations"
                              onChange={handleDropdownChange}
                              options={locationOptions}
                              title="Location"
                           />
                        )}

                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.selectedManufacturers
                                 ?.value as Option[]
                           }
                           isMulti
                           id="selectedManufacturers"
                           onChange={handleDropdownChange}
                           options={manufacturerOptions}
                           title="Manufacturers"
                        />

                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.selectedCategories?.value as Option[]
                           }
                           isMulti
                           id="selectedCategories"
                           onChange={handleDropdownChange}
                           options={categoryOptions}
                           title="Category"
                           menuPlacement="auto"
                        />

                        {/* <DateRangeFilter
                            dateProps={{
                                from: {
                                    defaultValue: tmpFilters?.gpsStartDate?.value as Date,
                                    error: errors?.gpsStartDate,
                                    id: 'gpsStartDate',
                                    placeholder: 'Start date'
                                },
                                to: {
                                    defaultValue: tmpFilters?.gpsEndDate?.value as Date,
                                    error: errors?.gpsEndDate,
                                    id: 'gpsEndDate',
                                    placeholder: 'End date'
                                }
                            }}
                            id='gpsStartDate'
                            onChange={modify}
                            title='Last GPS Scan'
                        />

                        <DropdownFilter
                           defaultValue={
                              tmpFilters?.lastScannedBy?.value as Option[]
                           }
                           id="lastScannedBy"
                           onChange={handleDropdownChange}
                           options={connectionOptions} // TODO: change to scanned by options
                           title="Last Scanned By"
                        /> */}
                     </form>
                  </Drawer>
               </SlidePanel>

               <Modal
                  isModalVisible={isModalExportVisible}
                  closeModal={handleExportClose}
                  title={modalTitle}
               >
                  <CsvDownloadForm
                     primaryActionText={exportPrimaryActionText}
                     secondaryActionText={exportSecondaryActionText}
                     criteria={''}
                     data={itemData}
                     fileName={exportFileName}
                     closeModal={handleExportClose}
                     path={basicPath.DOWNLOAD}
                     query={searchCriteria}
                     fullDownload={fullDownload}
                     filters={filterValues}
                     setIsDownloading={setIsDownloading}
                     isDownloading={isDownloading}
                     defaultParams={downloadRequest}
                     buttonRef={buttonRef}
                  />
               </Modal>

               {isCopyPrompt && (
                  <WarningModal
                     isModalOpen={isCopyPrompt}
                     handleClose={() => setIsCopyPrompt(false)}
                     title={'Copy Confirmation'}
                     message={'Are you sure you want to copy this item?'}
                     primaryButtonTitle={'Ok'}
                     primaryButtonMethod={() => handleCopy(Actions.Copy)}
                     primaryButtonVariant={'primary'}
                     secondaryButtonTitle={'Cancel'}
                     secondaryButtonMethod={() => setIsCopyPrompt(false)}
                     secondaryButtonVariant={'tertiary'}
                  />
               )}

               {isBarcodePrompt && (
                  <WarningModal
                     isModalOpen={isBarcodePrompt}
                     handleClose={() => setIsBarcodePrompt(false)}
                     title={'Barcode'}
                     message={`The barcode (${barcodeInvaidInput}) cannot be assigned`}
                     children={<Alert message={barcodeError} />}
                     zIndex="9999"
                     hasChildren={true}
                     primaryButtonTitle={'Close'}
                     primaryButtonVariant={'tertiary'}
                     primaryButtonMethod={() => setIsBarcodePrompt(false)}
                  />
               )}

               {isKitsToAddModal && (
                  <AddToKitModal
                     isModalOpen={isKitsToAddModal}
                     handleClose={() => handleClose('avoidReset')}
                     handleAddToKit={handleAddToKit}
                     cancel={() => isSetIsKitsToAdd(false)}
                     selectKit={itemKitOption}
                     addToKitQty={addToKitQty}
                     setAddToKitQty={setAddToKitQty}
                     itemForm={itemForm}
                  />
               )}

               {isKitsToDeleteModal && (
                  <Modal
                     isModalVisible={isKitsToDeleteModal}
                     closeModal={() => handleClose('avoidReset')}
                     title={promptTitle}
                  >
                     <DeleteKit
                        handleDeleteKit={handleDeleteKit}
                        cancel={() => isSetIsKitsToDelete(false)}
                        title=""
                        subTitle=""
                        actionButton={'REMOVE'}
                        itemForm={itemForm}
                     />
                  </Modal>
               )}

               {isDeletePrompt && (
                  <WarningModal
                     isModalOpen={isDeletePrompt}
                     handleClose={() => setIsDeletePrompt(false)}
                     title={'Delete Item'}
                     message={'Are you sure you want to delete this item?'}
                     primaryButtonTitle={'Delete'}
                     primaryButtonMethod={(id) => handleRemove(id)}
                     primaryButtonVariant={'danger'}
                     secondaryButtonTitle={'Cancel'}
                     secondaryButtonMethod={() => setIsDeletePrompt(false)}
                     secondaryButtonVariant={'tertiary'}
                  />
               )}
               {isModalVisible && (
                  <Modal
                     isModalVisible={isModalVisible}
                     closeModal={handleClose}
                     title={itemForm?.Title || 'Add Item'}
                     itemButtons={itemButtons}
                     permissions={itemForm.Permissions || []}
                     marginTop={isMobile && '2rem'}
                  >
                     <CrudItems
                        isNewItemNote={isNewItemNote}
                        setIsNewItemNote={setIsNewItemNote}
                        postItem={postItem}
                        setItemImages={setItemImages}
                        itemImages={itemImages}
                        deleteImage={deleteImage}
                        changePosition={changePosition}
                        isPositionLoading={isPositionLoading}
                        isPositionSuccess={isPositionSuccess}
                        setItemStatus={setItemStatus}
                        itemStatus={itemStatus}
                        activeValue={activeValue}
                        isLoading={isLoading}
                        itemTypes={itemTypeOptions}
                        categories={categoryOptions}
                        manufacturers={manufacturerOptions}
                        locations={locationActiveOptions}
                        conditions={conditionOptions}
                        itemNotesData={itemNotesData}
                        itemHistoryData={itemHistoryData}
                        handleAction={handleBarcodeCheck}
                        closeModal={handleClose}
                        itemId={itemId}
                        itemAction={itemAction}
                        setItemAction={setItemAction}
                        isModalVisible={isModalVisible}
                        itemForm={itemForm}
                        setItemForm={setItemForm}
                        resetRef={resetRef}
                        getItemView={getItemView}
                        refetchNoteTableData={refetchItemNotes}
                        isEmployeeUserViewingItemsPage={isEmployeeUser}
                        parentPageView={CrudItemsParentView.Items}
                        handleWorkflowAction={handleWorkflowAction}
                        username={`${profile?.Firstname ?? ''} ${
                           profile?.Lastname ?? ''
                        }`}
                     />
                  </Modal>
               )}
               {isManageKitModalOpen && (
                  <ManageKitModal
                     isModalOpen={isManageKitModalOpen}
                     handleClose={() => setIsManageKitModalOpen(false)}
                     kitId={itemId}
                     itemForm={itemForm}
                  />
               )}
               {isImportModalVisible && (
                  <ImportItemsModal
                     isOpen={isImportModalVisible}
                     handleClose={handleCloseImportModal}
                     uploadPercentage={uploadPercentage}
                     setUploadPercentage={setUploadPercentage}
                     currentView={uploadModalView}
                     setCurrentView={setUploadModalView}
                     importSelectedFile={importItems}
                     isImportingData={isImportingItems}
                     importError={importError}
                     importFileErrors={importFileErrors}
                     isImportingSuccess={isImportItemsSuccess}
                     handleSuccessfulImport={handleSuccessfulImport}
                     totalImagesImported={totalImagesImported}
                     totalItemsImported={totalItemsImported}
                  />
               )}
               {isQuantityModalOpen && (
                  <ItemQuantityModal
                     isModalOpen={isQuantityModalOpen}
                     handleClose={() => setIsQuantityModalOpen(false)}
                     handleAdjustTotal={handleIncrementDecrementQuantity}
                     handleCustomTotalChange={handleCustomTotalChange}
                     startWorkflowAction={handleCommenceWorkflow}
                     items={selectedItemsForWorkflow}
                     checkoutAction={checkoutAction}
                     zIndex={'999'}
                  />
               )}
               {isCheckoutModalOpen && (
                  <CheckoutModal
                     isModalOpen={isCheckoutModalOpen}
                     handleClose={() => setIsCheckoutModalOpen(false)}
                     checkoutAction={checkoutAction}
                     selectedItems={selectedItemsForWorkflow}
                     handlePageCancelAndReset={handleResetPageAfterCheckout}
                     zIndex={'999'}
                     viewTitle={true}
                     isAdminCompanyUser={isAdminCompanyUser}
                  />
               )}
               {isItemLimitPrompt && (
                  <WarningModal
                     isModalOpen={isItemLimitPrompt}
                     handleClose={() => setIsItemLimitPrompt(false)}
                     title={'Item Limit Reached'}
                     hasChildren
                     message="Need more items?"
                     children={
                        <Alert message="You have reached the item limit for your free account. Explore ShareMyToolbox paid system for more items and capabilities." />
                     }
                     zIndex="9999"
                     primaryButtonTitle={'Close'}
                     primaryButtonVariant={'tertiary'}
                     primaryButtonMethod={() => setIsItemLimitPrompt(false)}
                  />
               )}
            </>
         )}
      </div>
   )
}
